<template>
  <b-card>
    <b-row class="pb-1">
      <b-col md="6" cols="6">
        <div style="display: flex">
          <b-form-select v-model="currentType" @change="changeType" name="currentType" class="mr-1">
            <option :value="null">Todos</option>
            <option v-for="type in bankAccountTypesComputed" :key="type.id" :value="type.id">
              {{ type.name }}
            </option>
          </b-form-select>
          <b-form-input v-model="searchValue" placeholder="Buscar" debounce="400" />
        </div>
      </b-col>
      <b-col md="6" cols="6" class="text-right">
        <b-button variant="primary" class="px-1" @click="create()">
          <feather-icon size="1.2x" icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-table hover responsive="sm" :items="items" :fields="fields" :per-page="perPage" :busy="loading" show-empty>
      <template #cell(values)="data">
        <div class="d-flex align-items-center">
          <div>
            <strong>{{ data.item.values.holder }}</strong>
          </div>
        </div>
      </template>

      <template #cell(bank_id)="data">
        <div class="d-flex align-items-center">
          <div>
            <strong>{{ data.item.values.number }}</strong>
          </div>
        </div>
      </template>

      <!-- <template #cell(available)="data">
        <div class="d-flex align-items-center">
          <div>
            <strong>{{ data.item.values.identification }}</strong>
          </div>
        </div>
      </template> -->

      <template #cell(actions)="data">
        <div class="d-flex align-items-center">
          <account-change-status :account="data.item" />
          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <feather-icon icon="SettingsIcon" />
            </template>
            <b-dropdown-item @click="edit(data.item)">Editar</b-dropdown-item>
            <b-dropdown-item @click="deleteAccount(data.item)">Eliminar</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-5 pt-3">
          <b-spinner type="grow" class="align-middle"></b-spinner>
          <strong class="ml-1">Cargando</strong>
        </div>
      </template>

      <template #empty>
        <div class="text-center my-5 pt-3 d-flex w-100 justify-content-center align-items-center">
          <feather-icon icon="AlertCircleIcon" size="1.5x" />
          <strong class="ml-1">No hay registros para mostrar</strong>
        </div>
      </template>
    </b-table>
    <b-row v-show="items.length > 0 && !loading"><b-col md="2" sm="12" class="d-none d-sm-block">
        <b-form-group class="mb-0">
          <label class="d-inline-block mr-50">Por página</label>
          <b-form-select id="perPageSelect" :value="perPage" size="sm" :options="pageOptions" @change="perPageChange"
            style="width: auto" />
        </b-form-group>
      </b-col>
      <b-col v-show="totalRows > perPage" md="10" sm="12">
        <b-pagination striped hover responsive first-number last-number :value="currentPage" :total-rows="totalRows"
          :per-page="perPage" align="right" size="sm" class="my-0" @change="changePage" />
      </b-col>
    </b-row>
    <b-sidebar width="20%" v-model="open" bg-variant="white" right backdrop shadow
      :title="`${item ? 'Editar' : 'Nueva'} cuenta`">
      <add-and-edit-account v-if="!paymentOption.is_crypto" :item="item"
        :paymentMethodId="paymentOption.payment_method_id" :currencyId="paymentOption.payment_method.currency.id"
        :bankId="paymentOption.id" :accountTypes="paymentOption.account_types_array" :structure="paymentOption.structure"
        :cashMethod="paymentOption.cash_method" @saved="close" />
      <add-and-edit-crypto-account v-else :item="item" :paymentMethodId="paymentOption.payment_method_id"
        :currencyId="paymentOption.id" @saved="close" />
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BCol,
  BPagination,
  BRow,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BSidebar,
  VBToggle,
  BSpinner,
  BAvatar,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

import { confirmAlert } from '@/helpers/utils'
import numberMixins from '@/mixins/numberMixins'
import StatusTransationBadge from '@/components/StatusTransationBadge'
import UserBadge from '@/components/UserBadge'
import AddAndEditAccount from '@/components/AddAndEditAccount'
import AccountChangeStatus from '@/components/AccountChangeStatus'
import AddAndEditCryptoAccount from '@/components/AddAndEditCryptoAccount'

export default {
  name: 'PaymentOptionAccounts',
  props: {
    paymentOption: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    BCard,
    BCardText,
    BTable,
    BCol,
    BPagination,
    BRow,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BSidebar,
    BSpinner,
    StatusTransationBadge,
    UserBadge,
    BAvatar,
    AddAndEditAccount,
    AccountChangeStatus,
    AddAndEditCryptoAccount,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [numberMixins],
  data() {
    return {
      currentType: null,
      open: false,
      searchValue: '',
      item: null,
      fields: [
        {
          key: 'values',
          label: 'Titular',
          sortable: true,
        },
        {
          key: 'bank_id',
          label: 'Cuenta',
          sortable: true,
        },
        // {
        //   key: 'available',
        //   label: 'Identificacion',
        //   sortable: true,
        // },
        // {
        //   key: 'code',
        //   label: 'Pais',
        //   sortable: true,
        // },
        // {
        //   key: 'format_code',
        //   label: 'Codigo Moneda',
        //   sortable: true,
        // },
        {
          key: 'actions',
          label: 'Acciones',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.paymentOptionAccounts.items,
      perPage: (state) => state.paymentOptionAccounts.perPage,
      currentPage: (state) => state.paymentOptionAccounts.currentPage,
      totalRows: (state) => state.paymentOptionAccounts.totalRows,
      pageOptions: (state) => state.paymentOptionAccounts.pageOptions,
      searchTerm: (state) => state.paymentOptionAccounts.searchTerm,
      loading: (state) => state.paymentOptionAccounts.loading,
      bankAccountTypes: (state) => state.app.initData.bank_account_types,
    }),
    bankAccountTypesComputed() {
      if (!this.paymentOption.cash_method) {
        return this.bankAccountTypes.filter((item) => item.recharge_type_id === this.paymentOption.payment_method_id && item.key !== 'cash')
      } else if (this.paymentOption.cash_method) {
        return this.bankAccountTypes.filter((item) => item.recharge_type_id === this.paymentOption.payment_method_id && item.key === 'cash')
      }
      return this.bankAccountTypes.filter((item) => item.recharge_type_id === this.paymentOption.payment_method_id)
    },
  },
  methods: {
    changeType(type) {
      this.currentType = type
      this.getItems()
    },
    close() {
      this.open = false
      this.item = null
    },
    showDetail(id) {
      this.$router.push({ name: 'recharge-methods.detail', params: { id } })
    },
    async deleteAccount(item) {
      console.log(item.id)
      const res = await confirmAlert('¿Está seguro de eliminar esta cuenta?')
      if (res.value) {
        const r = await this.$store.dispatch('paymentOptionAccounts/delete', {
          id: item.id,
        })
        if(r.success) {
          this.$swal('Cuenta eliminada', 'La cuenta se elimino correctamente', 'success')
          this.getItems()
        } else {
          this.$swal('Error', 'La cuenta no se pudo eliminar', 'error')
        }
      }
    },
    showDocumentDetail(document) {
      this.$router.push({
        name: 'document-detail',
        params: { id: document.id, type: 'invoice' },
      })
    },
    create() {
      this.item = null
      this.openSidebar()
    },
    edit(item) {
      this.item = item
      this.openSidebar()
    },
    async deleteItem(item) {
      const res = await confirmAlert('¿Está seguro de eliminar este registro?')
      if (res.value) {
        const r = await this.$store.dispatch('paymentOptionAccounts/delete', {
          id: item.id,
        })
        this.$swal('Eliminado', 'El registro ha sido eliminado', 'success')
      }
    },
    openSidebar(open = true) {
      this.open = open
    },
    getItems(page = 1) {
      this.$store.dispatch('paymentOptionAccounts/get', {
        currentPage: page,
        currencyId: this.paymentOption.is_crypto ? this.paymentOption.id : this.paymentOption.payment_method.currency.id,
        bankId: this.paymentOption.is_crypto ? null : this.paymentOption.id,
        type: this.currentType,
        is_crypto: this.paymentOption.is_crypto
      })
    },
    changePage(page) {
      this.getItems(page)
    },
    perPageChange(perPage) {
      this.$store.dispatch('paymentOptionAccounts/perPageChange', perPage)
      this.getItems()
    },
    search(value) {
    },
  },
  mounted() {
    this.getItems()
  },
  watch: {
    searchValue(value) {
      this.$store.dispatch('paymentOptionAccounts/searchTermChange', value)
      this.getItems()
    },
    // paymentOption deep watch
    // paymentOption: {
    //   handler() {
    //     this.getItems()
    //   },
    //   deep: true,
    // }
  },
}
</script>

<style></style>
