<template>
  <b-form-checkbox
    switch
    size="lg"
    v-model="available"
    @change="changeStatus"
    variant="success"
  />
</template>
<script>
import { BFormCheckbox } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AccountChangeStatus',
  components: {
    BFormCheckbox,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      available: false,
    }
  },
  methods: {
    async changeStatus() {
      this.loading = true
      try {
        const res = await this.$store.dispatch('paymentOptionAccounts/changeStatus', {
          id: this.account.id,
          available: this.available,
        })

        if (res?.success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (error) {
        this.$store.dispatch('alert/error', error)
      }
      this.loading = false
    },
  },
  // watch deep account
  watch: {
    account: {
      handler() {
        this.available = this.account.available
      },
      deep: true,
    },
  },
  mounted() {
    this.available = this.account.available
  },
}
</script>
