<template>
  <div v-if="account">
    <validation-observer ref="createOrUpdateAccountForm">
      <b-form class="mt-2" @submit.prevent="createOrUpdateAccount">
        <b-container>
          <b-row>
            <b-col v-for="k in account" :key="k.key" md="12">
               <b-form-group v-if="k.key === 'type'" :label="k.name" :label-for="k.key">
                <validation-provider
                  #default="{ errors }"
                  :name="k.name"
                  rules="required"
                >
                  <b-form-select
                    :id="k.key"
                    v-model="k.value"
                    :state="errors.length > 0 ? false : null"
                    :name="k.key"
                  >
                    <option v-for="type in cryptoPlatforms" :key="type.id" :value="type.id">
                      {{ type.name }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group v-if="k.key !== 'bank_account_type_id' && k.key !== 'type' && k.key !== 'crypto_network_id'" :label="k.name" :label-for="k.key">
                <validation-provider
                  #default="{ errors }"
                  :name="k.name"
                  :rules="'required'"
                >
                  <b-form-input
                    :id="k.key"
                    v-model="k.value"
                    :state="errors.length > 0 ? false : null"
                    :name="k.key"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group v-if="k.key === 'crypto_network_id'" :label="k.name" :label-for="k.key">
                <validation-provider
                  #default="{ errors }"
                  :name="k.name"
                  rules="required"
                >
                  <b-form-select
                    :id="k.key"
                    v-model="k.value"
                    :state="errors.length > 0 ? false : null"
                    :name="k.key"
                  >
                    <option v-for="cn in cryptoNetworks" :key="cn.id" :value="cn.id">
                      {{ cn.name }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div
                class="d-flex justify-content-center border-top align-items-center py-5"
              >
                <b-button type="submit" variant="primary">Guardar</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BCard,
  VBToggle,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

const initData = [
    {
        "key": "type",
        "type": "string",
        "name": "Tipo"
    },
    {
        "key": "holder",
        "type": "string",
        "name": "Titular"
    },
    {
        "key": "number",
        "type": "string",
        "name": "Número de cuenta"
    },
    {
        "key": "identification",
        "type": "string",
        "name": "Identificación"
    },
    {
        "key": "bank_account_type_id",
        "type": "string",
        "name": "Tipo de cuenta"
    }
]


export default {
  components: {
    BContainer,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    vSelect,
    BCard,
    FeatherIcon,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    currencyId: {
      type: String,
      default: null,
    },
    paymentMethodId: {
      type: String,
      default: null,
    },
    structure: {
      type: Array,
      default: () => [
        {
            "key": "type",
            "type": "string",
            "name": "Tipo"
        },
        {
            "key": "holder",
            "type": "string",
            "name": "Titular"
        },
        {
            "key": "number",
            "type": "string",
            "name": "Número de cuenta"
        },
        {
            "key": "bank_account_type_id",
            "type": "string",
            "name": "Tipo de cuenta"
        },
        {
            "key": "crypto_network_id",
            "type": "string",
            "name": "Red Cripto"
        }
      ]
    },
  },
  data() {
    return {
      account: JSON.parse(JSON.stringify(initData))
    }
  },
  computed: {
    ...mapState({
      cryptoPlatforms: (state) => state.app.initData.crypto_platforms,
      cryptoNetworks: (state) => state.app.initData.cripto_networks,
      bankAccountTypes : (state) => state.app.initData.bank_account_types,
    }),
  },
  methods: {
    createOrUpdateAccount() {
        this.$refs.createOrUpdateAccountForm.validate().then( async (success) => {
            if (success) {
              // get value each field and create object key value
              const fields = this.account.reduce((acc, item) => {
                acc[item.key] = item.value
                return acc
              }, {})


              // Add bank_account_type_id
              // type is now crypto_platform_id
              const cryptoPlatform = this.cryptoPlatforms.find((item) => item.id === fields.type)
              const type = this.bankAccountTypes.find((item) => item.key === cryptoPlatform.key)
              let data = {
                ...fields,
                type: type.name,
                bank_account_type_id: type.id,
                currency_id: this.currencyId,
                bank_id: cryptoPlatform.id,
              }

              const cryptoNetwork = this.cryptoNetworks.find((item) => item.id === fields.crypto_network_id)
              data = {
                ...data,
                crypto_network_id: cryptoNetwork.id,
                crypto_network_name: cryptoNetwork.name,
              }

              // If is update
              if (this.item) {
                  data = {
                    ...data,
                    id: this.item.id
                  }
              }

              const res = await this.$store.dispatch('paymentOptionAccounts/save', data)


                if (res?.success) {
                    this.$emit('saved', this.account)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.message,
                            icon: 'CheckCircleIcon',
                            variant: 'success',
                        },
                    })

                    // reset form values
                    this.account = JSON.parse(JSON.stringify(this.structure ? this.structure : initData))
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.message,
                            icon: 'CheckCircleIcon',
                            variant: 'success',
                        },
                    })
                }
            }
        })
    },
  },
  watch: {
    item: {
      handler: function (val) {
        if (val) {
          this.account = []
          const s = JSON.parse(JSON.stringify(this.structure))
          s.forEach((i) => {
            if (i.key === 'type') {
              this.account.push({
                ...i,
                value: this.item.bank_id
              })
            } else if (i.key === 'crypto_network_id') {
              this.account.push({
                ...i,
                value: this.item.crypto_network_id
              })
            } else {
               this.account.push({
                ...i,
                value: this.item.values[i.key],
              })
            }


          })
        } else {
          this.account = JSON.parse(JSON.stringify(this.structure))
        }
      },
      deep: true,
    },
    structure: {
      handler: function (val) {
        if (val) {
          this.account = JSON.parse(JSON.stringify(this.structure))
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.account = JSON.parse(JSON.stringify(this.structure))
  },
}
</script>
