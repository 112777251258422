<template>
  <b-card>
    <b-row class="pb-1">
      <b-col md="3" cols="6">
        <b-form-input
          v-model="searchValue"
          placeholder="Buscar"
          debounce="400"
        />
      </b-col>
      <!-- <b-col md="9" cols="6" class="text-right">
        <b-button variant="primary" class="px-1" @click="create()">
          <feather-icon size="1.2x" icon="PlusIcon" />
        </b-button>
      </b-col> -->
    </b-row>
    <b-table
      hover
      responsive="sm"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :busy="loading"
      show-empty
    >
      <template #cell(sender)="data">
        <span class="text-capitalize">{{
          data.item.sender_id === id ? 'Envia' : 'Recibe'
        }}</span>
      </template>

      <template #cell(created_at)="data">
        {{ dateFormat(data.item.created_at) }}
      </template>

      <template #cell(hacienda)="data">
        <status-hacienda :status="data.item.hacienda" />
      </template>

      <template #cell(amount)="data">
        {{ numberFormat(data.item.amount) }}
      </template>

      <template #cell(actions)>
        <b-dropdown
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" />
          </template>
          <b-dropdown-item>Ver detalle</b-dropdown-item>
          <b-dropdown-item>Aprobar</b-dropdown-item>
          <b-dropdown-item>Declinar</b-dropdown-item>
        </b-dropdown>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-5 pt-3">
          <b-spinner type="grow" class="align-middle"></b-spinner>
          <strong class="ml-1">Cargando</strong>
        </div>
      </template>

      <template #empty>
        <div
          class="text-center my-5 pt-3 d-flex w-100 justify-content-center align-items-center"
        >
          <feather-icon icon="AlertCircleIcon" size="1.5x" />
          <strong class="ml-1">No hay registros para mostrar</strong>
        </div>
      </template>
    </b-table>
    <b-row v-show="items.length > 0 && !loading"
      ><b-col md="2" sm="12" class="d-none d-sm-block">
        <b-form-group class="mb-0">
          <label class="d-inline-block mr-50">Por página</label>
          <b-form-select
            id="perPageSelect"
            :value="perPage"
            size="sm"
            :options="pageOptions"
            @change="perPageChange"
            style="width: auto"
          />
        </b-form-group>
      </b-col>
      <b-col v-show="totalRows > perPage" md="10" sm="12">
        <b-pagination
          striped
          hover
          responsive
          first-number
          last-number
          :value="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
          @change="changePage"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BCol,
  BPagination,
  BRow,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BSidebar,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

import { confirmAlert } from '@/helpers/utils'
import numberMixins from '@/mixins/numberMixins'
import StatusHacienda from '@/components/StatusHacienda/Index.vue'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    BCard,
    BCardText,
    BTable,
    BCol,
    BPagination,
    BRow,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BSidebar,
    BSpinner,
    StatusHacienda,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [numberMixins],
  data() {
    return {
      open: false,
      searchValue: '',
      item: null,
      fields: [
        {
          key: 'reference',
          label: 'Referencia',
          sortable: true,
        },
        {
          key: 'sender',
          label: 'Envia/Recibe',
          sortable: true,
        },

        {
          key: 'amount',
          label: 'Monto',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Fecha',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Acciones',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.sendMoney.items,
      perPage: (state) => state.sendMoney.perPage,
      currentPage: (state) => state.sendMoney.currentPage,
      totalRows: (state) => state.sendMoney.totalRows,
      pageOptions: (state) => state.sendMoney.pageOptions,
      searchTerm: (state) => state.sendMoney.searchTerm,
      loading: (state) => state.sendMoney.loading,
    }),
  },
  methods: {
    showDocumentDetail(document) {
      this.$router.push({
        name: 'document-detail',
        params: { id: document.id, type: 'invoice' },
      })
    },
    create() {
      this.$router.push({
        name: 'document',
        params: { type: 'invoice', action: 'new' },
      })
    },
    edit(item) {
      this.item = item
      this.openSidebar()
    },
    async deleteItem(item) {
      const res = await confirmAlert('¿Está seguro de eliminar este registro?')

      if (res.value) {
        const r = await this.$store.dispatch('sendMoney/delete', {
          id: item.id,
        })
        this.$swal('Eliminado', 'El registro ha sido eliminado', 'success')
      }
    },
    openSidebar(open = true) {
      this.open = open
    },
    getItems(page = 1) {
      this.$store.dispatch('sendMoney/get', {
        currentPage: page,
        id: this.id,
      })
    },
    changePage(page) {
      this.getItems(page)
    },
    perPageChange(perPage) {
      this.$store.dispatch('sendMoney/perPageChange', perPage)
      this.getItems()
    },
    search(value) {
    },
  },
  mounted() {
    this.getItems()
  },
  watch: {
    searchValue(value) {
      this.$store.dispatch('sendMoney/searchTermChange', value)
      this.getItems()
    },
  },
}
</script>

<style></style>
