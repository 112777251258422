<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="paymentOption">
      <b-card>
        <div class="pb-3">
          <b-button variant="primary" class="btn-icon mr-1" @click="() => $router.go(-1)">
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <!-- <b-button variant="success" class="btn-icon mr-1">
            <feather-icon icon="RefreshCwIcon" size="16" />
          </b-button> -->
        </div>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <b-avatar v-if="paymentOption.icon_url" variant="primary" :src="paymentOption.icon_url" class="mr-1"
                size="lg"></b-avatar>
              <div>
                <h2>{{ paymentOption.name }}</h2>
                <!-- <h4>Codigo moneda: {{ paymentOption.format_code }}</h4> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <payment-option-accounts :paymentOption="paymentOption" />
      </b-card>
    </div>

  </b-overlay>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { confirmAlert } from '@/helpers/utils'
import PaymentOptionAccounts from '@/views/PaymentOptionAccounts'

moment.locale('es')
import {
  BOverlay,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BCardText,
  VBTooltip,
  BTab,
  BTabs,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'

import numberMixins from '@/mixins/numberMixins'
import Recharges from './Recharges.vue'
import Withdrawals from './Withdrawals.vue'
import SendMoney from './SendMoney.vue'

export default {
  components: {
    DatePicker,
    BBadge,
    vSelect,
    BOverlay,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BCardText,
    Recharges,
    Withdrawals,
    SendMoney,
    BTab,
    BTabs,
    PaymentOptionAccounts
  },
  mixins: [numberMixins],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapState({
      loading: (state) => state.paymentOptionDetail.loading,
      paymentOption: (state) => state.paymentOptionDetail.detail,
    }),
  },
  data() {
    return {}
  },
  watch: {},
  methods: {
    getDetail() {
      this.$store.dispatch('paymentOptionDetail/getDetail', {
        id: this.$route.params.id,
        is_crypto: this.$route.query.is_crypto,
      })
    },
  },
  mounted() {
    this.getDetail()
  },
}
</script>
