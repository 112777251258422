<template>
  <div>
    <feather-icon icon="CheckCircleIcon" class="text-success"></feather-icon>
  </div>
</template>

<script>
export default {
  name: 'StatusHacienda',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  watch: {},
}
</script>
